<template>
  <div class="header">
    <div class="header__container">
      <a href="/" class="header__logo">
        <img src="/img/logo-white.svg">
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LayoutHeader'
}
</script>
